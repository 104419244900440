/* global Site */
import React from 'react';
import { connect, Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { FlagsProvider } from 'react-unleash-flags';

import * as fromState from 'reducers';

import Navigation from './components/Navigation';
import configureStore from './config/configureStore';
import flagConfig from './helpers/flagConfig';

const container = document.getElementById('react-header');

const storeData = Site.defaultStore;
const store = configureStore(storeData);
Site.reduxstore = store;

// eslint-disable-next-line react/jsx-props-no-spreading
const Header = props => <Navigation {...props} isRouterless />;

const mapStateToProps = state => {
  return {
    isGalleryUser: fromState.getIsGalleryUser(state),
    isClientUser: fromState.getIsClientUser(state),
    isLoggedIn: fromState.getIsLoggedIn(state),
  };
};

const ConnectedHeader = connect(mapStateToProps, null)(Header);


const render = () => {
  if (container) {
    ReactDOM.render(
      <FlagsProvider config={flagConfig}>
        <Provider store={store}>
          <BrowserRouter>
            <Route path="/:region" component={ConnectedHeader} />
          </BrowserRouter>
        </Provider>
      </FlagsProvider>,
      container,
    );
  }
};

store.subscribe(render);
render();
